var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-group" } }),
          _c("h5", { staticClass: "d-inline ml-2" }, [_vm._v("Couriers")]),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _vm.pages > 1
                ? _c("CPagination", {
                    staticClass: "cl-pagination",
                    attrs: {
                      align: "center",
                      dots: false,
                      pages: _vm.pages,
                      "active-page": _vm.activePage,
                    },
                    on: {
                      "update:activePage": function ($event) {
                        _vm.activePage = $event
                      },
                      "update:active-page": function ($event) {
                        _vm.activePage = $event
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c(
            "CCard",
            { staticClass: "filters", attrs: { "accent-color": "warning" } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xl mb-2 mb-xl-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Hubs..",
                          label: "name",
                          options: _vm.hubs,
                          searchable: false,
                          reduce: (hub) => hub.id,
                          multiple: "",
                        },
                        on: { input: _vm.inputFilter },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (hub) {
                              return [
                                _c("strong", [_vm._v(_vm._s(hub.zip))]),
                                _vm._v(" - "),
                                _c("span", [_vm._v(_vm._s(hub.name) + " ")]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedHubs,
                          callback: function ($$v) {
                            _vm.selectedHubs = $$v
                          },
                          expression: "selectedHubs",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xl mb-2 mb-xl-0 pl-xl-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Status..",
                          options: [
                            { value: "active", label: "Is Working" },
                            { value: "inactive", label: "Is Not Working" },
                          ],
                          searchable: false,
                          reduce: (o) => o.value,
                          multiple: "",
                        },
                        on: { input: _vm.inputFilter },
                        model: {
                          value: _vm.selectedStatus,
                          callback: function ($$v) {
                            _vm.selectedStatus = $$v
                          },
                          expression: "selectedStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xl mb-2 mb-xl-0 pl-xl-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Contract model..",
                          options: [
                            {
                              value: "no_active_contract",
                              label: "- No active contract -",
                            },
                            { value: "per_order", label: "Per order" },
                            { value: "hourly", label: "Hourly" },
                          ],
                          reduce: (option) => option.value,
                          searchable: false,
                        },
                        on: { input: _vm.inputFilter },
                        model: {
                          value: _vm.selectedContractModel,
                          callback: function ($$v) {
                            _vm.selectedContractModel = $$v
                          },
                          expression: "selectedContractModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xl mb-0 pl-xl-0" },
                    [
                      _c("CInput", {
                        staticClass: "mb-0 search",
                        attrs: {
                          type: "search",
                          placeholder: "Search for something else ...",
                        },
                        on: { input: _vm.searchFilter },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("CDataTable", {
            attrs: {
              striped: "",
              hover: "",
              items: _vm.loadedItems,
              fields: _vm.fields,
              sorter: { external: true, resetable: true },
              itemsPerPageSelect: {
                external: true,
                values: [5, 15, 25, 50, 100, 250, 500],
              },
              "items-per-page": _vm.itemsPerPage,
              loading: _vm.loading,
              noItemsView: {
                noResults: "No filtering results are available!",
                noItems: "No courier found!",
              },
            },
            on: {
              "update:itemsPerPage": function ($event) {
                _vm.itemsPerPage = $event
              },
              "update:items-per-page": function ($event) {
                _vm.itemsPerPage = $event
              },
              "update:sorter-value": _vm.sorterValue,
              "pagination-change": _vm.paginationChange,
            },
            scopedSlots: _vm._u([
              {
                key: "image_icon",
                fn: function ({ item }) {
                  return [
                    _c(
                      "td",
                      [
                        _c("CImg", {
                          staticClass: "c-avatar-img",
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: item.image_icon || item.gravatar,
                            placeholderColor: "lightgray",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "first_name",
                fn: function ({ item }) {
                  return [
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "vertical-center flex-gap-1 mb-2" },
                        [
                          item.settings?.is_dispatcher
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: { content: "Dispatcher" },
                                      expression: "{ content: 'Dispatcher' }",
                                    },
                                  ],
                                  staticClass: "dot bg-secondary",
                                },
                                [_vm._v("👔")]
                              )
                            : item.settings?.is_subordinate
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        content: `#${item.detail.parent_id}'s subordinate`,
                                      },
                                      expression:
                                        "{ content: `#${item.detail.parent_id}'s subordinate` }",
                                    },
                                  ],
                                  staticClass: "dot bg-secondary",
                                },
                                [_vm._v("👷")]
                              )
                            : _vm._e(),
                          _c("strong", [_vm._v(_vm._s(item.first_name))]),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("#" + _vm._s(item.id)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "small" },
                        [
                          _c("CIcon", {
                            staticClass: "mr-1",
                            attrs: { name: "cil-at" },
                          }),
                          _vm._v(_vm._s(item.email)),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "small" },
                        [
                          _c("CIcon", {
                            staticClass: "mr-1",
                            attrs: { name: "cil-phone" },
                          }),
                          _vm._v(_vm._s(item.mobile)),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "contract_model",
                fn: function ({ item }) {
                  return [
                    _c("td", [
                      item.active_contract
                        ? _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("uppercase")(
                                    _vm._f("normalizeSentence")(
                                      item.active_contract.contract_model
                                    )
                                  )
                                ) + " / "
                              ),
                            ]),
                            item.active_contract.employment_type
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("uppercase")(
                                        _vm._f("normalizeSentence")(
                                          item.active_contract.employment_type
                                        )
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", { staticClass: "flash-it" }, [
                                  _vm._v("❓"),
                                ]),
                          ])
                        : _c("span", { staticClass: "flash-it text-danger" }, [
                            _vm._v("❌ No active contract!"),
                          ]),
                    ]),
                  ]
                },
              },
              {
                key: "vehicle",
                fn: function ({ item }) {
                  return [
                    _c("td", [
                      item.vehicle
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center flex-gap-2",
                            },
                            [
                              _c("MIcon", {
                                attrs: { tag: item.vehicle.mode },
                              }),
                              _c("span", { staticClass: "text-uppercase" }, [
                                _vm._v(_vm._s(item.vehicle.license_plate)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
              {
                key: "hubs",
                fn: function ({ item }) {
                  return [
                    _c(
                      "td",
                      { staticClass: "p-0" },
                      [
                        item._classes.includes("table-warning")
                          ? _c("CIcon", {
                              attrs: { name: "cil-warning", size: "lg" },
                            })
                          : _vm._e(),
                        _vm._l(item.hubs, function (hub) {
                          return _c(
                            "CBadge",
                            {
                              key: hub.id,
                              staticClass: "mx-1",
                              attrs: { color: "secondary" },
                            },
                            [_vm._v(_vm._s(hub.name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "is_working",
                fn: function ({ item, index }) {
                  return [
                    _c(
                      "td",
                      { staticClass: "text-center pr-4" },
                      [
                        item.status?.is_working_now
                          ? [
                              item.status?.on_break
                                ? [
                                    item.updating
                                      ? _c("CSpinner", {
                                          staticClass: "mb-1",
                                          attrs: {
                                            size: "sm",
                                            color: "warning",
                                          },
                                        })
                                      : _c("span", {
                                          staticClass: "dot bg-warning",
                                          attrs: { role: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.forceEndBreak(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                  ]
                                : [
                                    item.active_contract?.contract_model ===
                                    "per_order"
                                      ? [
                                          item.updating
                                            ? _c("CSpinner", {
                                                staticClass: "mb-1",
                                                attrs: {
                                                  size: "sm",
                                                  color: "success",
                                                },
                                              })
                                            : _c("span", {
                                                staticClass: "dot bg-success",
                                                attrs: { role: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.endWorkingStatus(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                        ]
                                      : _c(
                                          "CLink",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Edit Courier",
                                                params: { id: item.id },
                                                query: { tab: 4 },
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "dot bg-success",
                                            }),
                                          ]
                                        ),
                                  ],
                            ]
                          : [
                              item.active_contract?.contract_model ===
                              "per_order"
                                ? [
                                    item.updating
                                      ? _c("CSpinner", {
                                          staticClass: "mb-1",
                                          attrs: {
                                            size: "sm",
                                            color: "danger",
                                          },
                                        })
                                      : _c("span", {
                                          staticClass: "dot bg-danger",
                                          attrs: { role: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.startWorkingStatus(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                  ]
                                : _c(
                                    "CLink",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Edit Courier",
                                          params: { id: item.id },
                                          query: { tab: 4 },
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "dot bg-danger",
                                      }),
                                    ]
                                  ),
                            ],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "on_order",
                fn: function ({ item }) {
                  return [
                    _c("td", {}, [
                      _c(
                        "div",
                        { staticClass: "vertical-center separate-items" },
                        [
                          item.dispatcher_detail?.has_assignable_orders
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "c-tooltip",
                                        rawName: "v-c-tooltip",
                                        value: {
                                          content: `${item.dispatcher_detail.assigned_orders_count}/${item.dispatcher_detail.assignable_orders_count} order assigned.`,
                                        },
                                        expression:
                                          "{ content: `${item.dispatcher_detail.assigned_orders_count}/${item.dispatcher_detail.assignable_orders_count} order assigned.` }",
                                      },
                                    ],
                                    class: {
                                      "dot highlighted-dot text-white font-weight-bold": true,
                                      "bg-info":
                                        item.dispatcher_detail
                                          .assigned_orders_count ===
                                        item.dispatcher_detail
                                          .assignable_orders_count,
                                      "bg-warning":
                                        item.dispatcher_detail
                                          .assigned_orders_count <
                                        item.dispatcher_detail
                                          .assignable_orders_count,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.dispatcher_detail
                                            .assignable_orders_count
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          item.status?.on_order
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vertical-center justify-content-center separate-items pl-0",
                                },
                                _vm._l(
                                  item.active_orders,
                                  function (order, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "vertical-center justify-content-center flex-gap-1",
                                      },
                                      [
                                        _c(
                                          "CLink",
                                          {
                                            directives: [
                                              {
                                                name: "c-tooltip",
                                                rawName: "v-c-tooltip",
                                                value: {
                                                  content: `o#${order.id}`,
                                                  placement: "top",
                                                },
                                                expression:
                                                  "{ content: `o#${order.id}`, placement: 'top' }",
                                              },
                                            ],
                                            staticStyle: { height: "20px" },
                                            attrs: {
                                              to: {
                                                name: "Order Details",
                                                params: { id: order.id },
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "dot bg-success",
                                            }),
                                          ]
                                        ),
                                        order.revocable
                                          ? _c(
                                              "CLink",
                                              {
                                                directives: [
                                                  {
                                                    name: "c-tooltip",
                                                    rawName: "v-c-tooltip",
                                                    value: {
                                                      content:
                                                        "Transfer or revoke order",
                                                      placement: "top",
                                                    },
                                                    expression:
                                                      "{ content: 'Transfer or revoke order', placement: 'top' }",
                                                  },
                                                ],
                                                staticClass:
                                                  "align-self-baseline",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.transferOrRevokeOrder(
                                                      order
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-transfer",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", { staticClass: "vertical-center" }, [
                                _c("span", { staticClass: "dot" }),
                              ]),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "work-times",
                fn: function ({ item }) {
                  return [
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                size: "sm",
                                color: "info",
                                variant: "ghost",
                                shape: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showWeeklyWorkTimes(item)
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-calendar" } })],
                            1
                          ),
                          _c(
                            "ul",
                            { staticClass: "list-unstyled today-work-times" },
                            [
                              _vm._l(
                                item.today_work_times?.times,
                                function (time) {
                                  return [
                                    _c("li", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between align-items-start",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("ucfirst")(time.type)
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "text-right" },
                                            [
                                              time.end_time
                                                ? [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .moment(
                                                              time.start_time
                                                            )
                                                            .format("HH:mm")
                                                        )
                                                      ),
                                                    ]),
                                                    _c("CIcon", {
                                                      staticClass: "mx-1",
                                                      attrs: {
                                                        name: "cil-arrow-right",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(time.end_time)
                                                      ),
                                                    ]),
                                                  ]
                                                : [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "d-block",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .moment(
                                                                time.start_time
                                                              )
                                                              .format(
                                                                "D MMM HH:mm"
                                                              )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "em",
                                                      {
                                                        staticClass:
                                                          "text-primary",
                                                      },
                                                      [_vm._v("(Ongoing)")]
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                      time.type === "hourly"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center justify-content-end ml-2 mt-1",
                                            },
                                            [
                                              time.start_time_confirmed
                                                ? [
                                                    _c(
                                                      "span",
                                                      { staticClass: "mr-1" },
                                                      [_vm._v("✅")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          time.start_time_confirmed
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                : [
                                                    _c(
                                                      "span",
                                                      { staticClass: "mr-1" },
                                                      [_vm._v("❌")]
                                                    ),
                                                    _c(
                                                      "em",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [_vm._v("Not confirmed")]
                                                    ),
                                                  ],
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                }
                              ),
                              item.today_work_times?.times.length === 0
                                ? _c(
                                    "li",
                                    {
                                      staticClass:
                                        "text-danger small font-italic",
                                    },
                                    [_vm._v(" No work hours found for today! ")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("mc-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
          }),
          _c("TransferOrRevokeOrder", {
            attrs: {
              courierId: _vm.selectedOrder?.courier_id,
              dispatcherId: _vm.selectedOrder?.dispatcher_id,
              order: _vm.$globalFunc.extractKeys(_vm.selectedOrder, [
                "id",
                "roDesiredDelivireyTime",
                "roAproxDeliveryDateToCourier",
                "roAddress",
                "roCity",
                "roPostal_code",
                "roCountryId",
              ]),
              restaurant: _vm.$globalFunc.extractKeys(
                _vm.selectedOrder?.restaurant,
                ["id", "restaurant_postcode"]
              ),
            },
            on: { transferred: _vm.getAllItems },
          }),
          _c("WeeklyWorkTimes", {
            attrs: { courier: _vm.selectedCourier },
            on: {
              close: function ($event) {
                _vm.selectedCourier = null
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }