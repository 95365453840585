var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.courierWeeklyWorkTimesAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["courierWeeklyWorkTimesAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit(
              "toggle",
              "courierWeeklyWorkTimesAsideShow"
            )
          },
        },
      }),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErrorAlert,
              expression: "showErrorAlert",
            },
          ],
          staticClass: "list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Error ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-danger list-group-item-divider",
              attrs: { href: "#" },
            },
            [
              _c(
                "div",
                { staticClass: "c-avatar float-left mr-2" },
                [
                  _c("CIcon", {
                    staticClass: "c-avatar-img text-danger",
                    attrs: { name: "cil-bug", size: "xl" },
                  }),
                ],
                1
              ),
              _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showErrorAlert,
              expression: "!showErrorAlert",
            },
          ],
          staticClass: "scrollable list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Weekly Work Times ")]
          ),
          _vm._l(_vm.items, function (item, dIndex) {
            return [
              _c(
                "CListGroupItem",
                { staticClass: "list-group-item-accent-secondary" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.formatRelativeDate(item.date))),
                      ]),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.formatDate(item.date))),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._l(item.times, function (time, tIndex) {
                return [
                  _c(
                    "CListGroupItem",
                    { staticClass: "list-group-item-divider" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center ml-2" },
                            [
                              _c("span", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.getTypeIcon(time.type))),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm._f("ucfirst")(time.type))),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              time.end_time
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.formatTime(time.start_time))
                                      ),
                                    ]),
                                    _c("CIcon", {
                                      staticClass: "mx-1",
                                      attrs: { name: "cil-arrow-right" },
                                    }),
                                    _c("span", [_vm._v(_vm._s(time.end_time))]),
                                  ]
                                : [
                                    _c("span", [
                                      _vm._v(_vm._s(time.start_time)),
                                    ]),
                                    _c(
                                      "em",
                                      { staticClass: "text-primary ml-2" },
                                      [_vm._v("(Ongoing)")]
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      ),
                      time.type === "hourly"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-end ml-2 mt-1",
                            },
                            [
                              time.start_time_confirmed
                                ? [
                                    _c("span", { staticClass: "mr-1" }, [
                                      _vm._v("✅"),
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(time.start_time_confirmed)),
                                    ]),
                                  ]
                                : [
                                    _c("span", { staticClass: "mr-1" }, [
                                      _vm._v("❌"),
                                    ]),
                                    _c("em", { staticClass: "text-danger" }, [
                                      _vm._v("Not confirmed"),
                                    ]),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
              item.times.length === 0
                ? [
                    _c(
                      "CListGroupItem",
                      { staticClass: "list-group-item-divider" },
                      [
                        _c("em", { staticClass: "text-danger ml-2" }, [
                          _vm._v("No work times found!"),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading || _vm.submitted,
            expression: "loading || submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }