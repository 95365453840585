var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [_c("CCol", { attrs: { sm: "12" } }, [_c("CourierList")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }